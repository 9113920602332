import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RouteWithLayout from './RouteWithLayout'
import { Minimal as MinimalLayout } from '../layouts';
import { Sponsor as SponserLayout } from '../layouts';
import { Full as FullLayout } from '../layouts';
import VerifyIdentity from '../views/Verification/VerifyIdentity';


const PublicRoutes = () => {
    return (
        <Switch>
            <RouteWithLayout
                component={lazy(() => (import('../views/SignIn')))}
                exact
                layout={MinimalLayout}
                path="/signIn/:token?/:email?"
            />
            <RouteWithLayout
                  component={lazy(() => (import('../views/SignIn/maintainance')))}
                exact
                layout={MinimalLayout}
                path="/maintainance"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/ActivateAccount')))}
                exact
                layout={MinimalLayout}
                path="/activateAccount"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/CreateAccount')))}
                exact
                layout={FullLayout}
                path="/createAccount"
            />

            <RouteWithLayout
                component={lazy(() => (import('../views/CreateAccount/LinkAccount')))}
                exact
                layout={FullLayout}
                path="/addbank"
            />
             <RouteWithLayout
                component={lazy(() => (import('../views/CreateAccount/FundAccount')))}
                exact
                layout={FullLayout}
                path="/fundaccount"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/CreateAccount/FundAccountForm')))}
                exact
                layout={FullLayout}
                path="/trusteetotrusteetransfer"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/CreateAccount/FundAccountForm')))}
                exact
                layout={FullLayout}
                path="/hsarollover"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/ForgotPassword')))}
                exact
                layout={MinimalLayout}
                path="/forgotPassword"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/Verification')))}
                exact
                layout={MinimalLayout}
                path="/verification/:token/:email"
            />
            
            {/* <RouteWithLayout
                component={lazy(() => (import('../views/Verification')))}
                exact
                layout={MinimalLayout}
                path="/verification"
            /> */}

            <RouteWithLayout
                component={lazy(() => (import('../views/ChangePassword')))}
                exact
                layout={MinimalLayout}
                path="/changePassword/:token/:email"
            />

            {/* Start new route for Sponsor */}
            <RouteWithLayout
                component={lazy(() => (import('../views/sponsor/sponsorVerification')))}
                exact
                layout={MinimalLayout}
                path="/sponsor/verification/:token/:email"
            />

            <RouteWithLayout
                component={lazy(() => (import('../views/sponsor/SignIn')))}
                exact
                layout={MinimalLayout}
                path="/sponsor/signIn/:token?/:email?"
            />

            <RouteWithLayout
                component={lazy(() => (import('../views/sponsor/ActivateAccount')))}
                exact
                layout={MinimalLayout}
                path="/sponsor/activateaccount"
            />

            <RouteWithLayout
                component={lazy(() => (import('../views/sponsor/ForgotPassword')))}
                exact
                layout={MinimalLayout}
                path="/sponsor/forgotPassword"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/Verification/VerifyIdentity')))}
                exact
                layout={MinimalLayout}
                path="/VerifyIdentity"
            />


            <RouteWithLayout
                component={lazy(() => (import('../views/sponsor/ChangePassword')))}
                exact
                layout={MinimalLayout}
                path="/sponsor/changePassword/:token/:email"
            />

            <RouteWithLayout
                component={lazy(() => (import('../views/sponsor/Dashboard')))}
                exact
                layout={SponserLayout}
                path="/sponsor/dashboard"
            />

            <RouteWithLayout
                component={lazy(() => (import('../views/sponsor/Participant')))}
                exact
                layout={SponserLayout}
                path="/sponsor/participant"
            />

            <RouteWithLayout
                component={lazy(() => (import('../views/sponsor/ParticipantDetails')))}
                exact
                layout={SponserLayout}
                path="/sponsor/participantdetails"
            />

            <RouteWithLayout
                component={lazy(() => (import('../views/sponsor/Documents')))}
                exact
                layout={SponserLayout}
                path="/sponsor/documents"
            />
            <Redirect from='/' to='/signIn' />
        </Switch>
    )
};

export default PublicRoutes;
