import { Action, ActionType } from "../../model";
import { AuthSignInReq, AuthSignInRes, UserInfo } from "../../model/auth";
import createReducer from "./createReducer";

export const auth = createReducer({}, {
	[ActionType.SIGNIN](state: AuthSignInReq, action: Action<AuthSignInRes>) {
		return action.payload
	},
	[ActionType.USER_INFO](state: AuthSignInRes, action: Action<UserInfo>) {
		return {
			...state,
			user: action.payload
		}
	},
	[ActionType.SIGNOUT](state: AuthSignInRes, action: Action<any>) {
		return action.payload;
	}
});