import { ActionType, SponsorActionType } from "../../model";
import { notifyMe } from "../../helper";

export const getstates = () => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_states', {}, getState().sponsorauth.access_token);
		return dispatch({ type: SponsorActionType.SPONSOR_STATES, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'get_states', method: 'error', message: 'Error', description: error });
	}
};


export const getaccessToken = () => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('create_access_token', {});
		return resp;
	} catch (error) {
		notifyMe({ key: 'get_states', method: 'error', message: 'Error', description: error });
	}
};

export const createlinktoken = () => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('create_link_token', {});
		return resp;
	} catch (error) {
		notifyMe({ key: 'get_states', method: 'error', message: 'Error', description: error });
	}
};

export const postPublicToken = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('create_plaid_access_token', data, '', getState().auth.access_token);
		return dispatch({ type: ActionType.POST_PUBLIC_TOKEN, payload: resp });
	} catch (error) {
		notifyMe({ key: 'get_states', method: 'error', message: 'Error', description: error });
	}
};

export const checkLinkedBankAccounts = () => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_plaid_linked_bank_account_by_individual', {}, getState().sponsorauth.access_token);
		return dispatch({ type: ActionType.LINKED_BANK_ACCOUNTS, payload: resp });
	} catch (error) {
		notifyMe({ key: 'get_states', method: 'error', message: 'Error', description: error });
	}
};
export const checkAccessToken = () => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('check_plaid_linked_account', {}, getState().auth.access_token);
		return dispatch({ type: ActionType.CHECK_ACCESS_TOKEN, payload: resp });
		
	} catch (error) {
		notifyMe({ key: 'get_states', method: 'error', message: 'Error', description: error });
	}
};
export const getCashStatment = () => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_statements', {}, getState().auth.access_token);
		return dispatch({ type: ActionType.CASH_STATEMENT, payload: resp });
		
	} catch (error) {
		notifyMe({ key: 'get_states', method: 'error', message: 'Error', description: error });
	}
};

export const getInvestmentStatment = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_dw_statements_documents_confirms', data, getState().auth.access_token);
		return dispatch({ type:  ActionType.INVESTMENT_STATEMENT, payload: resp });
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};

export const getDwTransactions = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_dw_transactions', data,  getState().auth.access_token);
		return dispatch({ type: ActionType.GET_DW_TRANSACTIONS, payload: resp });
	} catch (error) {
		notifyMe({ key: 'getInvestment', method: 'error', message: 'Error', description: error });
	}
};

export const getInvestmentFileKey = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_statement_file_by_fileKey', data, getState().auth.access_token);
		return dispatch({ type:  ActionType.INVESTMENT_FILE_KEY, payload: resp });
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};


export const participantNotificationStatus = () => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('participant_notification_status', {}, getState().auth.access_token);
		return dispatch({ type: ActionType.PARTICIPANT_NOTIFICATION_STATUS, payload: resp });
	} catch (error) {
		notifyMe({ key: 'get_states', method: 'error', message: 'Error', description: error });
	}
};



export const sendNotificationEmail = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('send_notification_email', data, '', getState().auth.access_token);
		return dispatch({ type: ActionType.SEND_NOTIFICATION_EMAIL, payload: resp });
	} catch (error) {
		notifyMe({ key: 'get_states', method: 'error', message: 'Error', description: error });
	}
};

/**Send  */

export const importContributions = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('import_contributions_only?ssn='+data, '', '', '');
		return dispatch({ type: ActionType.IMPORTCONTRIBUTIONS, payload: resp });
	} catch (error) {
		notifyMe({ key: 'get_states', method: 'error', message: 'Error', description: error });
	}
};

export const fetchdocumentfile = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('pullfileUrl', '', '', getState().auth.access_token);
		return dispatch({ type: ActionType.COMMON_DOCUMENT_FILE, payload: resp });
	} catch (error) {
		notifyMe({ key: 'get_states', method: 'error', message: 'Error', description: error });
	}
};



