import React, { lazy, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import RouteWithLayout from './RouteWithLayout'
import { Main as MainLayout } from '../layouts';
import { Sponsor as SponserLayout } from '../layouts';
import InvestmentDashboard from '../views/Investments/Dashboard/InvestmentDashboard';
import { Full as FullLayout } from '../layouts';
import { useActions, authAction, authSponsorAction, bankAction } from "../actions";

const PrivateRoutes = () => {

    /**bank data */
    // const bankActions = useActions(bankAction);

    // const [bankadded, setbankadded] = useState(false);

    // useEffect(() => {
    //     _fetchbankadded();
    // }, []);

    // const _fetchbankadded = async () => {
    //     let response = await bankActions.fetchBankInfo();
    //     if(response && response.payload.bankadded === false) {
    //         setbankadded(false);
    //     }  else {
    //         setbankadded(true);
    //     }
    // }

    console.log(window.localStorage.getItem('bankadded') )

return (
    <Switch>
            <>
                {
                    window.localStorage.getItem('bankadded') == 'false' 
                    ? 
                        <>
                            <RouteWithLayout
                                component={lazy(() => (import('../views/CreateAccount/LinkAccount')))}
                                exact
                                layout={FullLayout}
                                path="/addbank"
                            />
                            <RouteWithLayout
                                component={lazy(() => (import('../views/CreateAccount/FundAccountForm')))}
                                exact
                                layout={FullLayout}
                                path="/trusteetotrusteetransfer"
                            />
                            <RouteWithLayout
                                component={lazy(() => (import('../views/CreateAccount/FundAccountForm')))}
                                exact
                                layout={FullLayout}
                                path="/hsarollover"
                            />
                            <RouteWithLayout
                                component={lazy(() => (import('../views/CreateAccount/FundAccount')))}
                                exact
                                layout={FullLayout}
                                path="/FundAccount"
                            />
                            <Redirect from='/' to='/FundAccount' />
                        </>
                    :
                    <>
                        <RouteWithLayout
                            component={lazy(() => (import('../views/CreateAccount/LinkAccount')))}
                            exact
                            layout={FullLayout}
                            path="/addbank"
                        />
                        <RouteWithLayout
                            component={lazy(() => (import('../views/Dashboard')))}
                            exact
                            layout={MainLayout}
                            path="/dashboard"
                        />

                        <RouteWithLayout
                            component={lazy(() => (import('../views/DashboardNew')))}
                            exact
                            layout={MainLayout}
                            path="/dashboard_new"
                        />

                        <RouteWithLayout
                            component={lazy(() => (import('../views/Investments/Dashboard/InvestmentDashboard')))}
                            exact
                            layout={MainLayout}
                            path="/investments_dashboard"
                        />


                        <RouteWithLayout
                            component={lazy(() => (import('../views/Transactions')))}
                            exact
                            layout={MainLayout}
                            path="/transactions"
                        />
                        <RouteWithLayout
                            component={lazy(() => (import('../views/Contributions')))}
                            exact
                            layout={MainLayout}
                            path="/contributions"
                        />

                        {/*Expense routes*/}
                        <RouteWithLayout
                            component={lazy(() => (import('../views/Expenses')))}
                            exact
                            layout={MainLayout}
                            path="/expenses"
                        />

                        <RouteWithLayout
                            component={lazy(() => (import('../views/Expenses/AddExpenses')))}
                            exact
                            layout={MainLayout}
                            path="/addexpenses"
                        />
                        {/*Expense routes end*/}

                        <RouteWithLayout
                            component={lazy(() => (import('../views/Documents')))}
                            exact
                            layout={MainLayout}
                            path="/documents"
                        />
                        <RouteWithLayout
                            component={lazy(() => (import('../views/Investments')))}
                            exact
                            layout={MainLayout}
                            path="/investment_choices"
                        />
                        <RouteWithLayout
                            component={lazy(() => (import('../views/Investments/InvestmentsGridTwo')))}
                            exact
                            layout={MainLayout}
                            path="/investment_choices2"
                        />

                        <RouteWithLayout
                            component={lazy(() => (import('../views/Investments/InvestmentListView')))}
                            exact
                            layout={MainLayout}
                            path="/investment-list-view"
                        />

                        <RouteWithLayout
                            component={lazy(() => (import('../views/Investments/InvestmentsListViewPopularNdTrend')))}
                            exact
                            layout={MainLayout}
                            path="/Trending-and-Popular"
                        />

                        <RouteWithLayout
                            component={lazy(() => (import('../views/InvestmentDashboard')))}
                            exact
                            layout={MainLayout}
                            path="/allInvestment"
                        />

                        <RouteWithLayout
                            component={lazy(() => (import('../views/ForYou')))}
                            exact
                            layout={MainLayout}
                            path="/forYou"
                        />
                        <RouteWithLayout
                            component={lazy(() => (import('../views/Settings')))}
                            exact
                            layout={MainLayout}
                            path="/settings/profile"
                        />
                        <RouteWithLayout
                            component={lazy(() => (import('../views/Settings')))}
                            exact
                            layout={MainLayout}
                            path="/settings/dependents"
                        />
                        <RouteWithLayout
                            component={lazy(() => (import('../views/Settings')))}
                            exact
                            layout={MainLayout}
                            path="/settings/banking"
                        />
                        <RouteWithLayout
                            component={lazy(() => (import('../views/Settings')))}
                            exact
                            layout={MainLayout}
                            path="/settings/beneficiaries"
                        />
                        <RouteWithLayout
                            component={lazy(() => (import('../views/Settings')))}
                            exact
                            layout={MainLayout}
                            path="/settings/preferences"
                        />
                        <RouteWithLayout
                            component={lazy(() => (import('../views/Documents')))}
                            exact
                            layout={MainLayout}
                            path="/settings/documents"
                        />
                        <RouteWithLayout
                            component={lazy(() => (import('../views/MessageCenter')))}
                            exact
                            layout={MainLayout}
                            path="/messagecenter"
                        />

                        <RouteWithLayout
                            component={lazy(() => (import('../views/Investments/FundTransfer/FundTabs')))}
                            exact
                            layout={MainLayout}
                            path="/fund-transfer"
                        />

                        <RouteWithLayout
                            component={lazy(() => (import('../views/Investments/FundTransfer/TransferYourHSADollar')))}
                            exact
                            layout={MainLayout}
                            path="/transfer-hsa-dollars"
                        />
                        <RouteWithLayout
                            component={lazy(() => (import('../views/Investments/FundTransfer/transaction2')))}
                            exact
                            layout={MainLayout}
                            path="/transaction2"
                        />

                        <RouteWithLayout
                            component={lazy(() => (import('../views/Investments/FundTransfer/transaction3')))}
                            exact
                            layout={MainLayout}
                            path="/transaction3"
                        />
                        <RouteWithLayout
                            component={lazy(() => (import('../views/CreateAccount/FundAccount')))}
                            exact
                            layout={FullLayout}
                            path="/fundaccount"
                        />
                        <Redirect from='/' to='/dashboard' />
                    </>
                }
            </>
            
    </Switch>
  )
};


export default PrivateRoutes;
