import { AuthSignInReq, ResetLoginAttemptReq } from "../model/auth";
import { ActionType, SponsorActionType } from "../model";
import { notifyMe } from "../helper";


export const SignIn = (data: AuthSignInReq) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('auth0_login', data);
		
		if (resp.code === 200) {
			// console.log(resp);
			if(resp && resp.bankadded === 'na') {
				window.localStorage.setItem('bankadded', 'false');
			}
			dispatch({ type: ActionType.SIGNIN, payload: resp.data });
			return true;
		} else if (resp.code === 201) {
			notifyMe({ key: 'signIn', method: 'error', message: 'Error', description: resp.message });
			return false;
		} else if (resp.code === 207) {
			notifyMe({ key: 'signIn', method: 'error', message: resp.message, description: resp.data.error_description });
			return false;
		}
	} catch (error) {
		notifyMe({ key: 'signIn', method: 'error', message: 'Error', description: error });
	}
};

export const ActivateAccount = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('send_activation_email', data);
		return dispatch({ type: ActionType.ACTIVATE_ACCOUNT, payload: resp.message });
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const ResetLoginAttempts = (data: ResetLoginAttemptReq) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('reset_login_attempts', data);
		if (resp.code === 200) {
			notifyMe({ key: 'resetLoginAttempt', method: 'success', message: 'Success', description: resp.message });
			dispatch({ type: ActionType.RESET_LOGIN_ATTEMPTS, payload: resp.data });
			return true;
		} else if (resp.code === 404) {
			notifyMe({ key: 'verifyAcc', method: 'error', message: 'Error', description: resp.message });
			return false;
		}
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const GetUserInfoByAccessToken = () => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.get('get_userinfo_by_access_token', {}, getState().auth.access_token);
		let paydata: any = {};
		if(resp.data) {
			paydata = resp.data;
			paydata['user_type'] = resp?.user_type?.user_type;
			paydata['bankadded'] = resp?.bankadded;
		}
		return dispatch({
			type: ActionType.USER_INFO,
			payload: paydata
		});
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const SignOut = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: SponsorActionType.SPONSOR_SIGNOUT, payload: {} })
	return dispatch({ type: ActionType.SIGNOUT, payload: {} });
};

export const VerifyAccount = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const resp = await api.post('verify_individual_by_firstname_lastname_ssn4', data);
		if (resp.code === 200) {
			notifyMe({ key: 'verifyAcc', method: 'success', message: 'Success', description: resp.message });
			dispatch({ type: ActionType.VERIFY_ACCOUNT, payload: resp.data });
			return true;
		} else if (resp.code && resp.code !== 200) {
			notifyMe({ key: 'verifyAcc', method: 'error', message: 'Error', description: resp.message });
			return false;
		}
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const ForgotPassword = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('send_forgotpassword_email', data);
		if (resp.code === 200) {
			notifyMe({ key: 'forgotPwd', method: 'success', message: 'Please check your email for reset instructions' });
			return dispatch({ type: ActionType.FORGOT_PASSWORD, payload: resp.data });
		} else if (resp.code && resp.code !== 200) {
			notifyMe({ key: 'forgotPwd', method: 'error', message: 'Error', description: 'Error in sending email' });
		}
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const CheckVerificationToken = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('check_token_status', data);
		if (resp.code === 200) {
			dispatch({ type: ActionType.CHECK_VERIFICATION_TOKEN, payload: resp });
			return {
				isValid: true,
				code: resp.code
			};
		} else if (resp.code && resp.code !== 200) {
			notifyMe({ key: 'verifyToken', method: 'error', message: 'Verification Token', description: resp.message });
			return {
				isValid: false,
				code: resp.code
			};
		}
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const ChangePassword = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('change_password', data);
		if (resp.code === 200) {
			notifyMe({ key: 'changePwd', method: 'success', message: 'Success', description: 'Your password was reset successfully. Please sign in to continue' });
			return dispatch({ type: ActionType.CHANGE_PASSWORD, payload: resp.data });
		} else if (resp.code && resp.code !== 200){
			notifyMe({ key: 'changePwd', method: 'error', message: 'Error', description: resp.message });
		}
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const ResendVerificationMail = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('send_invitation_email_again', data);
		if (resp.code === 200) {
			notifyMe({ key: 'resendVerifyEmail', method: 'success', message: 'Success', description: resp.message });
			return dispatch({ type: ActionType.RESEND_VERIFICATION_MAIL, payload: resp });
		}
		else if (resp.code && resp.code !== 200) {
			notifyMe({ key: 'resendVerifyEmail', method: 'error', message: 'Error', description: resp.message });
		}
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const AuthAutomaticSignIn = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('auth_automatic_signup_login', data);
		if (resp.code === 200) {
			// console.log(resp);
			if(resp && resp.bankadded === 'na') {
				window.localStorage.setItem('bankadded', 'false');
			}
			return dispatch({ type: ActionType.SIGNIN, payload: resp.data });
		} else if (resp.code && resp.code !== 200) {
			notifyMe({ key: 'signIn', method: 'error', message: resp.message, description: resp.data.error_description });
		}
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const FundAccount=(data:any) => async (dispatch: Function, getState: Function, api: any)=>{
	try {
		let resp = await api.post('save_hsa_rollover_info', data, '', getState().auth.access_token);
		if (resp.code === 200) {
			return dispatch({ type: ActionType.FUND_ACCOUNT_DATA, payload: resp});
		} else if (resp.code && resp.code !== 200) {
			notifyMe({ key: 'fundAccount', method: 'error', message: resp.message, description: resp.data.error_description });
		}
	} catch (err) {
		notifyMe({ key: 'fundAccount', method: 'error', message: 'Admin Error', description: 'Please contact admin!' });
	}
}

export const CreateHsaAccount=(data:any) => async (dispatch: Function, getState: Function, api: any)=>{
	try {
		let resp = await api.post('create_dw_hsa_account', {}, '', getState().auth.access_token);
		if (resp.code === 200) {
			return dispatch({ type: ActionType.DW_HSA_ACCOUNT_DATA, payload: resp});
		} else if (resp.code && resp.code !== 200) {
			notifyMe({ key: 'fundAccount', method: 'error', message: resp.message, description: resp.data.error_description });
		}
	} catch (err) {
		notifyMe({ key: 'fundAccount', method: 'error', message: 'Admin Error', description: 'Please contact admin!' });
	}
}



export const SignUpIndividual = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('signupindividual', data);

		if (resp.code === 200) {
			return dispatch({ type: ActionType.SIGNUP_INDIVIDUAL, payload: resp });
		} else if (resp.code === 201) {
			notifyMe({ key: 'signUp', method: 'error', message: 'Error', description: resp.message });
			return dispatch({ type: ActionType.SIGNUP_INDIVIDUAL, payload: {} });
		} else if (resp.code === 207) {
			notifyMe({ key: 'signUp', method: 'error', message: resp.message, description: resp.data.error_description });
			return dispatch({ type: ActionType.SIGNUP_INDIVIDUAL, payload: {} });
		}
	} catch (error) {
		console.error('Auth Error:', error);
	}
};
